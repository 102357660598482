@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .modal-position {
    position: absolute;
    right: 3rem;
    top: 5.4rem;
  }
}

:root {
  --primary-color: #ffb600;
  --secondary-color: #ffc127;
  --third-color: #ffc73b;
  --hover-bg-color: #feeab9;
  --error-color: #ce0303;
  --primary-radius: 5px !important;
  --placeholder-color: #000 !important;
}

:root.dark {
  --placeholder-color: #fff !important;
}

html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: #f5f5f5;
}

html.dark {
  --tw-bg-opacity: 1;
  background: rgb(17 24 39 / var(--tw-bg-opacity));
}

@media (prefers-color-scheme: dark) {
  html,
  body {
    --tw-bg-opacity: 1;
    background: rgb(17 24 39 / var(--tw-bg-opacity));
  }
}

@media (prefers-color-scheme: light) {
  html,
  body {
    background: #f5f5f5;
  }
}

.show-modal {
  transition: max-height 0.3s ease-out;
  overflow: hidden;
  pointer-events: auto;
}

.hide-modal {
  max-height: 0 !important;
  transition: max-height 0.3s ease-in;
  pointer-events: none !important;
  overflow: hidden !important;
}

.sidebar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(255, 255, 255);
  background-color: #f5f5f5;
}

.sidebar::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  border-radius: 10px;
}

html.dark .sidebar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(31 41 55 / var(--tw-bg-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}

html.dark .sidebar::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

html.dark .sidebar::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
}

html.dark .swal2-popup {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}

html.dark .swal2-title,
.swal2-html-container {
  color: white;
}

html.dark .swal2-styled.swal2-confirm {
  background-color: var(--primary-color) !important;
}

html.dark .swal2-styled.swal2-cancel {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity)) !important;
}

html.dark .swal2-styled.swal2-default-outline:focus {
  box-shadow: 0 0 0 3px var(--secondary-color) !important;
}

.swal2-styled.swal2-confirm {
  background-color: var(--primary-color) !important;
}

.swal2-styled.swal2-default-outline:focus {
  box-shadow: 0 0 0 3px var(--secondary-color) !important;
}

.rounded-input {
  display: none;
}

.rounded-input {
  width: 100%;
  padding: 120px 0 0 0;
  height: 100px;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: url("https://cdn1.iconfinder.com/data/icons/hawcons/32/698394-icon-130-cloud-upload-512.png")
    center center no-repeat #e4e4e4;
  border-radius: 20px;
  background-size: 60px 60px;
}

.invisible-scroll {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.invisible-scroll::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
